import styled from 'styled-components';
import { IconButton as MUIIconButton, Typography } from '@material-ui/core';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';

import { WithMyTheme } from '@keaze/web/theme';
import {
  TextField as CPTextField,
  TextFieldProps as CPTextFieldProps,
} from '@keaze/web/components/textField';

interface TextFieldProps extends WithMyTheme {
  $isValue: boolean;
}

export const Autocomplete = styled(MuiAutocomplete)`
  //  .MuiAutocomplete-root {border: 1px solid green;}
  .MuiAutocomplete-input {
    width: 100% !important;
    -webkit-text-fill-color: ${({ theme: { colors } }) => colors.greens.green0};
  }
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
    padding: 0 !important;
  }
  &.MuiFormControl-root {
    background-color: #fff !important;
  }
  &.Mui-focused .MuiFilledInput-notchedOutline,
  &:hover .MuiFilledInput-notchedOutline {
    border-color: ${({ theme: { colors, breakpoints } }) =>
      colors.greys.greys2};
  }

  .MuiAutocomplete-inputRoot {
    padding: ${({ theme: { spacing, colors } }) => spacing(0, 1)};
  }
  button {
    ${({ theme: { colors, breakpoints } }) => `
    right: 0 !important; 
    top: 0px !important; 

    font-size: 16px !important;
    ${breakpoints.up('sm')} {    margin-top: 5px; margin-right: 3px; }
    
     background-color: ${colors.whites.white1};
  `}
  }
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root']
    .MuiAutocomplete-input {
    &::placeholder {
      -webkit-text-fill-color: ${({ theme: { colors } }) =>
        colors.greens.green0};
      font-weight: bold;
    }

    border-radius: 0;
    padding: 10px;
    font-size: 16px !important;
    font-weight: bold;

    height: 18px;
    background-color: ${({ theme: { colors } }) => colors.whites.white1};
    // width: 70% !important
    padding-right: 25px;
  }
`;

export const IconButton = styled(MUIIconButton)`
  position: absolute;
  right: ${({ theme: { spacing } }) => spacing(1.5)}px;
`;

export const Label = styled(Typography)`
  ${({
    theme: {
      transitions: { easing, duration },
    },
  }) => `
    font-weight: 500;
    width: 100%;
    padding: 8px 16px;
    transition: ${duration.standard}ms ${easing.easeInOut};
  `}
`;

export const TextField = styled(CPTextField)<TextFieldProps & CPTextFieldProps>`
  ${({
    theme: {
      palette,
      colors,
      transitions: { duration, easing },
    },
    $isValue,
  }) => `
    .MuiInputAdornment-positionStart {
      svg {
        transition: ${duration.standard}ms ${easing.easeInOut};
        stroke: ${$isValue ? palette.secondary.main : colors.greys.greys17};
      }
    } 
  `}
`;
