import { FC } from 'react';
import Image from 'next/image';
import { Grid, PaperProps, Box } from '@material-ui/core';

import { CustomPaper as UICustomPaper } from './customPaper.styles';

export const CustomPaper: FC<PaperProps> = ({ children, ...rest }) => {
  return (
    <UICustomPaper {...rest}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid item>{children}</Grid>
        <Grid item container direction="row-reverse">
          <Grid item>
            <Box mx={1} my={1} height="18px">
              <Image
                src="/png/powered_by_google_on_white.png"
                alt="Powered by google "
                width="144"
                height="18"
                quality="100"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </UICustomPaper>
  );
};
